export enum Location {
  /**
   * First page with the calculator
   */
  SETUP = 'setup',
  CONTACTS = 'contacts',
  INVESTOR = 'investor',
  RESULT = 'result',
}

export enum PhonePosition {
  MESSAGE = 'message',
  FOOTER = 'footer',
}

export function useDataLayer() {
  const gtm = useGtm()

  function onPhoneClick(location: Location, position: PhonePosition) {
    gtm?.trackEvent({
      event: 'james_phone_click',
      position,
      location,
    })
  }

  function onSliderChange(value: number) {
    gtm?.trackEvent({
      event: 'james_slider_move',
      value,
    })
  }

  function onButtonClick(location: Location) {
    gtm?.trackEvent({
      event: 'james_button_click',
      location,
    })
  }

  return {
    onPhoneClick,
    onSliderChange,
    onButtonClick,
  }
}
